import React from 'react';
import { SKY_BLUE } from '../../assets/colors';

const styles = {
    container: {
        backgroundColor: SKY_BLUE,
        borderWidth: 0,
        color: 'white',
        fontFamily: 'Bebas Neue',
        fontWeight: 400,
        fontSize: 20,
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 32,
        paddingRight: 32
    }
};

const StandardButton = ({ style, text, onClick, disabled }) => (
    <button
        style={{...styles.container, ...style}}
        onClick={onClick}
        disabled={disabled}
    >
        {text}
    </button>
);

export default StandardButton;