import React, { useContext } from "react";
import { navigate } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { TranslationContext } from '../translation/translationContext';
import Screen from '../components/views/screen';
import Navbar from '../components/navbars/outsideNavbar';
import { VERY_LIGHT_BLUE, DARK_BLUE } from '../assets/colors';
import { outsideNavbarHeight } from '../assets/dimensions';
import StandardButton from '../components/buttons/standardButton';

const styles = {
  container: {
    backgroundColor: VERY_LIGHT_BLUE,
  },
  title: {
    marginTop: outsideNavbarHeight + 48,
    alignSelf: 'flex-start',
    fontFamily: 'roboto',
    fontWeight: 700,
    fontSize: 45,
    color: '#151E6A',
    marginLeft: '10%',
    marginRight: '10%',
    marginBottom: 48
  },
  grid: {
    alignSelf: 'center',
    marginBottom: 60,
    width: '60%'
  },
  faq: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 8
  },
  question: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 22,
    marginBottom: 8
  },
  answer: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 17,
    width: '70%',
    'line-height': '150%',
  },
  contactButton: {
    marginTop: 60,
    marginBottom: 400,
    alignSelf: 'flex-end',
    marginRight: '20%'
  }
};

const QandA = ({ question, answer, link }) => (
    <div style={styles.faq}>
        <p style={styles.question}>{question}</p>
        <p
          style={{
            ...styles.answer,
            textDecoration: !!link ? 'underline' : 'none',
            color: !!link ? DARK_BLUE : 'black',
          }}
          onClick={() => {
            if (link) {
              navigate(link);
            }
          }}>
            {answer}
          </p>
    </div>
);

const FaqPage = () => {
  const { translate } = useContext(TranslationContext);

  const faqs = translate('faqs');

  return (
    <Screen style={styles.container}>
        <Navbar currentTab={'/faq'} />
        <h1 style={styles.title}>{translate('faqTitle')}</h1>
        <Grid
            style={styles.grid}
            container
            justifyContent='space-between'
            alignItems='flex-start'
            spacing={2}
        >
            {Object.values(faqs).map(({ question, answer, link }) => (
                <Grid key={question} >
                    <QandA key={question} question={question} answer={answer} link={link} />
                </Grid>
            ))}
        </Grid>
        <StandardButton
            style={styles.contactButton}
            text={translate('anotherQuestion')}
            onClick={() => navigate('/#contacts')}
        />
    </Screen>
  )
};

export default FaqPage;
