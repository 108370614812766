import React, { useContext } from 'react';
import { Link } from "gatsby";
import { MEDIUM_BLUE, LIGHT_BLUE, PURPLE } from '../../assets/colors';
import { TranslationContext } from '../../translation/translationContext';
import TranslationView from '../views/translationView';
import { outsideNavbarHeight } from '../../assets/dimensions';
 
const styles = {
    container: {
        width: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: outsideNavbarHeight,
        backgroundColor: LIGHT_BLUE,
    },
    rightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    left: {
        marginLeft: 48,
    },
    right: {
        marginLeft: 42,
        marginRight: 48
    },
    link: {
        marginLeft: 30,
        marginRight: 30,
        textDecoration: 'none',
        color: MEDIUM_BLUE,
        fontFamily: "Roboto", // TODO
        fontWeight: 400, // TODO,
        fontSize: 24,
    }
};

const renderLinks = (links = [], currentTab = null) => links.map(({ to, text }) => (
    <Link key={to} style={{...styles.link, color: to === currentTab ? PURPLE : MEDIUM_BLUE}} to={to}>{text}</Link>
));

const Navbar = ({ currentTab }) => {
    const { translate } = useContext(TranslationContext);
    
    const leftLinks = [
        {text: translate('home'), to: '/'},
        {text: translate('aboutUs'), to: '/about'},
        {text: translate('contact'), to: '/#contacts'},
        {text: translate('faq'), to: '/faq'},
    ];
    
    const rightLinks = [
        {text: translate('login'), to: '/login'},
    ];

    return (
        <div style={styles.container}>
            <div style={styles.left}>
                {renderLinks(leftLinks, currentTab)}
            </div>
            <div style={styles.rightSide}>
                <TranslationView />
                <div style={styles.right}>
                    {renderLinks(rightLinks, currentTab)}
                </div>
            </div>
        </div>
    )
};

export default Navbar;